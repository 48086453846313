<template>
  <div class="px-5 py-20 mx-auto max-w-screen-xl lg:px-0">
    <div class="grid lg:grid-cols-3 overflow-hidden w-full">
      <div class="space-y-6 px-8 py-6 shadow-xl">
        <h2 class="text-4xl uppercase text-brand-red font-amatic-sc">
          Willkommen im {{ title }}
        </h2>
        <div>
          <p class="font-bold uppercase" v-if="description">Thema</p>
          <p>{{ description }}</p>
          <p class="text-xs text-gray-600 italic">Von {{ person }}</p>
        </div>
        <p class="select-none">
          Wir freuen uns darauf, Sie nach dem Zoom Meeting wieder bei uns im
          Livestream begrüßen zu dürfen.
        </p>
        <h2 class="font-semibold" v-if="code">
          <span class="select-none">Kenncode für Zoom: </span
          ><span class="font-bold underline">{{ code }}</span>
        </h2>
        <div class="flex justify-between select-none">
          <router-link
            to="/"
            class="
              px-5
              py-3
              text-white
              bg-brand-blue
              rounded-lg
              font-semibold
              text-lg
              uppercase
            "
          >
            Zurück
          </router-link>
          <a
            :href="zoomLink"
            target="_blank"
            class="
              px-5
              py-3
              text-white
              bg-brand-blue
              rounded-lg
              font-semibold
              text-lg
              uppercase
              grid
              place-items-center
            "
          >
            Zoom Meeting
          </a>
        </div>
      </div>
      <div class="relative col-span-2 shadow-xl">
        <p class="py-5 text-4xl uppercase text-brand-red font-amatic-sc">
          Damit Sie in das Zoom Meeting kommen, klicken Sie bitte, wie unten
          demonstriert, auf den Link auf der Zoom Seite.
        </p>
        <div>
          <img src="@/assets/Bilder/explaining.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import meetings from "../assets/meetings.json";
import { ref } from "vue";

export default {
  setup() {
    const route = useRoute();

    let item = ref([]);

    let routesForen = [];
    let routesWorkshops = [];

    meetings.forEach((meeting) => {
      meeting.foren.forEach((forum) => {
        routesForen.push(forum.route);
      });
      meeting.workshops.forEach((workshop) => {
        routesWorkshops.push(workshop.route);
      });
    });

    routesForen.forEach((foren, index) => {
      if (route.path === foren) {
        item.value.push(meetings[0].foren[index]);
      }
    });

    routesWorkshops.forEach((workshop, index) => {
      if (route.path === workshop) {
        item.value.push(meetings[0].workshops[index]);
      }
    });

    let person = item.value[0].person;
    let description = item.value[0].description;
    let zoomLink = item.value[0].zoomLink;
    let title = item.value[0].title;
    let code = item.value[0].code;

    return {
      code,
      title,
      zoomLink,
      description,
      person,
      meetings,
      item,
    };
  },
};
</script>
